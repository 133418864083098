<template>
  <div class="download">
    <a href="javascript:;" class="temp_xls" @click="download">重新下载导入模板</a>
  </div>
</template>
<script>
export default {
  name: "Download",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      shareid: 0,
      merchid: 0
    }
  },
  mounted() {
    if (this.$route.query.shareid && this.$route.query.shareid > 0) {
      this.shareid = this.$route.query.shareid;
    } else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    console.log("merchid:" + this.merchid, "shareid:" + this.shareid);
    if (this.$route.query.origin != 'xcx') {
      this.$parent.getmerchname(this.merchid, "下载导入模板");
    }
    
    this.init();
  },
  methods: {
    init() {
      console.log("ssss");
      this.download()
    },
    download() {
      window.location.href="https://api.midbest.cn/images/address_temp.xlsx";
    },
  },
}
</script>
<style lang="less">
  .download {
    width: 100%;
    min-height: ceil(100vh);
    background-color: #f0f0f0;
    overflow: hidden;

    .temp_xls {
      display: block;
      margin: 20px;
      background-color: #fff;
      color: #333;
      padding: 5px 10px;
      border-radius: 4px;
      width: 130px;
      line-height: 24px;
    }
  }

</style>